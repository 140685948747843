import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import step1 from './../assets/images/step1.jpg';
import step2 from './../assets/images/step2.jpg';
import step3 from './../assets/images/step3.jpg';
import step4 from './../assets/images/step4.jpg';

import './css/Steps.css';

const Steps = () => {
  return (
    <Container fluid className="steps-section text-center py-5">
      <Container>
        <h2 className="mb-5">
          Como Funciona?
        </h2>
        <Row>
          <Col md={3} className="mb-4">
            <div className="step-number">
              <Image src={step1} alt="Passo 1" className="img-fluid rounded-circle" />
            </div>
            <h5 className="step-title">PASSO 01</h5>
            <p className="step-text">Peça sua bateria do local que estiver e vamos até você.</p>
          </Col>

          <Col md={3} className="mb-4">
            <div className="step-number">
              <img src={step2} alt="Passo 2" className="img-fluid rounded-circle" />
            </div>
            <h5 className="step-title">PASSO 02</h5>
            <p className="step-text">
              Contate nossa central de vendas por WhatsApp, telefone ou e-mail para confirmar modelo e valor do produto.
            </p>
          </Col>

          <Col md={3} className="mb-4">
            <div className="step-number">
              <img src={step3} alt="Passo 3" className="img-fluid rounded-circle" />
            </div>
            <h5 className="step-title">PASSO 03</h5>
            <p className="step-text">Finalize seu pedido com nossos atendentes e é só aguardar a entrega.</p>
          </Col>

          <Col md={3} className="mb-4">
            <div className="step-number">
              <img src={step4} alt="Passo 4" className="img-fluid rounded-circle" />
            </div>
            <h5 className="step-title">PASSO 04</h5>
            <p className="step-text">
              Nossos técnicos estão habilitados a fazer a instalação com segurança, e após receberão o valor combinado.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Steps;
