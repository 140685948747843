import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import InputMask from 'react-input-mask';
import { sendContact } from '../services/apiService';
import CustomModal from '../components/CustomModal';
import './css/Contact.css';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  const mapStyles = {
    height: "450px",
    width: "100%"
  };

  const defaultCenter = {
    lat: -9.65686,
    lng: -35.715804
  };

  const validateForm = () => {
    const newErrors = {};
    if (!name) newErrors.name = ['O nome é obrigatório.'];
    if (!email) newErrors.email = ['O email é obrigatório.'];
    if (!phone) newErrors.phone = ['O telefone é obrigatório.'];
    if (!message) newErrors.message = ['A mensagem é obrigatória.'];
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    setLoading(true);

    try {
      const response = await sendContact({
        name,
        email,
        phone,
        message
      });

      // Verifica se a resposta contém um sucesso
      if (response) {
        setModalTitle(response.title);
        setModalText(response.message);
        setShowModal(true);
        // Limpar campos após o envio
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
        setErrors({});
      }
    } catch (error) {
      let errorResponse = {};
      try {
        errorResponse = JSON.parse(error);
      } catch {
        errorResponse = { error: { message: 'Erro desconhecido' } };
      }
      
      setErrors(errorResponse.error?.details || { global: 'Ocorreu um erro desconhecido.' });
      if (!errorResponse.error?.details || typeof errorResponse.error?.details === "string") {
        setModalText(typeof errorResponse.error?.details === "string" ? errorResponse.error?.details : "Ocorreu um erro desconhecido");
        setModalTitle("Ocorreu um erro");
        setShowModal(true);
      }

    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <Container fluid className="contact-section text-left py-1" id="fale-conosco">
      <Container>
        <h2 className="mb-5 text-center">Fale Conosco</h2>
        <Row>
          <Col md={6} className="mb-4">
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={19}
                center={defaultCenter}
              >
                <Marker position={defaultCenter} title={process.env.REACT_APP_NAME} />
              </GoogleMap>
            </LoadScript>
          </Col>
          <Col md={6} className="mb-2">
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicName" className='mb-2'>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite seu nome"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setErrors(prev => ({ ...prev, name: '' }));
                  }}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name ? errors.name[0] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formBasicEmail" className='mb-2'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Digite seu email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setErrors(prev => ({ ...prev, email: '' }));
                  }}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email ? errors.email[0] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formBasicPhone" className='mb-2'>
                <Form.Label>Telefone</Form.Label>
                <InputMask
                  mask="(99) 99999-9999"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setErrors(prev => ({ ...prev, phone: '' }));
                  }}
                  isInvalid={!!errors.phone}
                >
                  {() => <Form.Control placeholder="Digite seu telefone" isInvalid={!!errors.phone} />}
                </InputMask>
                <Form.Control.Feedback type="invalid">
                  {errors.phone ? errors.phone[0] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formBasicMessage" className='mb-2'>
                <Form.Label>Mensagem</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Digite sua mensagem"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    setErrors(prev => ({ ...prev, message: '' }));
                  }}
                  isInvalid={!!errors.message}
                />
                <Form.Control.Feedback type="invalid">{errors.message ? errors.message[0] : ''}</Form.Control.Feedback>
              </Form.Group>
              <Button variant="primary" type="submit" size="lg" className="mt-3" disabled={loading}>
                {loading ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                    Carregando...
                  </>
                ) : 'Enviar Mensagem'}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>

      {/* Modal para mostrar mensagens de sucesso ou erro */}
      <CustomModal
        show={showModal}
        handleClose={handleCloseModal}
        title={modalTitle}
        text={modalText}
      />
    </Container>
  );
};

export default Contact;
