import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import america from './../assets/images/america.webp';
import tudor from './../assets/images/tudor.webp';
import heliar from './../assets/images/heliar.webp';
import moura from './../assets/images/moura.webp';

import './css/Products.css';

const Products = () => {
  return (
    <Container fluid className="products-section text-center py-5">
      <Container>
        <h2 className="mb-5">
          Produtos {process.env.REACT_APP_NAME}
        </h2>
        <Row>
          <Col md={3} className="mb-4">
            <div className="product-img">
              <Image src={moura} alt="Passo 1" className="img-fluid rounded-circle" />
            </div>
            <h5 className="product-title">Moura</h5>
          </Col>

          <Col md={3} className="mb-4">
            <div className="product-img">
              <img src={heliar} alt="Passo 2" className="img-fluid rounded-circle" />
            </div>
            <h5 className="product-title">Heliar</h5>
          </Col>

          <Col md={3} className="mb-4">
            <div className="product-img">
              <img src={tudor} alt="Passo 3" className="img-fluid rounded-circle" />
            </div>
            <h5 className="product-title">Tudor</h5>
          </Col>

          <Col md={3} className="mb-4">
            <div className="product-img">
              <img src={america} alt="Passo 4" className="img-fluid rounded-circle" />
            </div>
            <h5 className="product-title">America</h5>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Products;
