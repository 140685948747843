import React, { useState } from 'react';
import { Modal, Form, Spinner, Button } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { sendColeta } from '../services/apiService';
import CustomModal from './CustomModal';
import './css/Footer.css';

const ModalColeta = (
  {
    showCollectionModal,
    handleCloseCollectionModal
  }) => {

  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // modal de alerta 
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const handleCloseModal = () => setShowModal(false);

  const validateForm = () => {
    const newErrors = {};
    if (!name) newErrors.name = ['O nome é obrigatório.'];
    if (!company) newErrors.company = ['O nome da empresa é obrigatório.'];
    if (!email) newErrors.email = ['O email é obrigatório.'];
    if (!phone) newErrors.phone = ['O telefone é obrigatório.'];
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) return; // não envia se houver erros

    setLoading(true);

    try {
      const response = await sendColeta({
        name,
        company,
        email,
        phone
      });

      // Verifica se a resposta contém um sucesso
      if (response) {
        handleCloseCollectionModal();
        setModalTitle(response.title);
        setModalText(response.message);
        setShowModal(true);
        // Limpar campos após o envio
        setName('');
        setCompany('');
        setEmail('');
        setPhone('');
        setErrors({});
      }
    } catch (error) {
      let errorResponse = {};
      try {
        errorResponse = JSON.parse(error);
      } catch {
        errorResponse = { error: { message: 'Erro desconhecido' } };
      }

      setErrors(errorResponse.error?.details || { global: 'Ocorreu um erro desconhecido.' });
      if (!errorResponse.error?.details || typeof errorResponse.error?.details === "string") {
        setModalText(typeof errorResponse.error?.details === "string" ? errorResponse.error?.details : "Ocorreu um erro desconhecido");
        setModalTitle("Ocorreu um erro");
        setShowModal(true);
      }
    } finally {
      setLoading(false);
    }
  };



  return (
    <>
      <Modal show={showCollectionModal} onHide={handleCloseCollectionModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Solicitação de Coleta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName" className="mb-2">
              <Form.Label>Nome do Encarregado</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o nome do encarregado"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setErrors(prev => ({ ...prev, name: '' }));
                }}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name ? errors.name[0] : ''}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formCompany" className="mb-2">
              <Form.Label>Nome da Empresa</Form.Label>
              <Form.Control
                type="text"
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                  setErrors(prev => ({ ...prev, company: '' }));
                }}
                isInvalid={!!errors.company}
                placeholder="Digite o nome da empresa"
              />
              <Form.Control.Feedback type="invalid">
                {errors.company ? errors.company[0] : ''}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formEmail" className="mb-2">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Digite seu email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrors(prev => ({ ...prev, email: '' }));
                }}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email ? errors.email[0] : ''}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formPhone" className="mb-2">
              <Form.Label>Telefone</Form.Label>
              <InputMask
                mask="(99) 99999-9999"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  setErrors(prev => ({ ...prev, phone: '' }));
                }}
                isInvalid={!!errors.phone}
              >
                {() => <Form.Control placeholder="Digite seu telefone" isInvalid={!!errors.phone} />}
              </InputMask>
              <Form.Control.Feedback type="invalid">
                {errors.phone ? errors.phone[0] : ''}
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" size="lg" type="submit" className="btn-block mb-2" disabled={loading}>
              {loading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                  Carregando...
                </>
              ) : 'Enviar'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {/* Modal para mostrar mensagens de sucesso ou erro */}
      <CustomModal
        show={showModal}
        handleClose={handleCloseModal}
        title={modalTitle}
        text={modalText}
      />
    </>
  );
};

export default ModalColeta;
