import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';
import { Container, Button, Row, Col, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Footer from '../components/Footer';
import Header from '../components/Header';
import './css/index.css';
import FloatButtons from '../components/FloatButtons';
import PhoneButtons from '../components/PhoneButtons';
import Steps from '../components/Steps';
import Products from '../components/Products';
import AboutUs from '../components/AboutUs';
import Contact from '../components/Contact';

const Index = () => {

    return (
        <>
            <Container fluid style={{ background: '#373c59' }} className='no-padding no-margin'>
                <Container>
                    <Helmet>
                        <title>{process.env.REACT_APP_NAME}</title>
                        <meta name="description" content={`Somos uma empresa especializada na venda, instalação e manutenção preventiva de baterias para carros, caminhões, motos, maquinas agrícolas, embarcações náuticas, jet skys, grupos geradores, carros elétricos e sistemas estacionários (especificas para Nobreaks de informática e emergência).`} />
                    </Helmet>
                    <Header />
                </Container>
                <Container fluid className='element'>
                    <Container>
                        <Row>
                            <Col xl={12} className='line-0-content'>
                                <h2>Bateria em maceió <label className='bg-success rounded'>em até 50 minutos</label></h2>
                            </Col>
                            <Col xl={6} className='line-1-content'>
                                <p>
                                    Ficou sem bateria e precisa de ajuda? Não se preocupe! Estamos prontos para resolver seu problema de forma rápida e segura.
                                </p>
                            </Col>
                            <Col xl={12}>
                                <Row>
                                    <Col xl={6} xxl={6} sm={6} xs={12} className='line-2-content'>
                                        <Button variant='success' size='lg' href='https://api.whatsapp.com/send?phone=5582988490802' target='_blank'>
                                            <FontAwesomeIcon icon={faWhatsapp} className='mr-2' />
                                            Pedir Agora
                                        </Button>
                                        <p>Parcele em até 8x sem juros</p>
                                        <Image src='images/cartoes.png' className='img-fluid' />
                                        <h5 className='text-white mt-4'>Horário de Funcionamento</h5>
                                        <p className='no-padding no-margin'>Seg à Seg 08:00 - 00:00</p>
                                        <p className='no-padding no-margin'>(Incluindo Domingos e Feriados)</p>
                                    </Col>
                                    <Col xl={6} xxl={6} sm={6} xs={12}>
                                        <Image src='images/main-battery-1.png' className='img-fluid img-banner' />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <PhoneButtons />
                <Steps />
                <AboutUs />
                <Products />
                <Contact />
                <FloatButtons />
                <Footer />
            </Container>
        </>
    );
};

export default Index;
