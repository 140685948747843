import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './css/CustomModal.css';

const CustomModal = ({ show, handleClose, title, text }) => {
    return (
        <Modal show={show} onHide={handleClose} centered className="ios-modal">
            <Modal.Header closeButton className="ios-modal-header">
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="ios-modal-body">
                {text}
            </Modal.Body>
            <Modal.Footer className="ios-modal-footer">
                <Button variant="primary" onClick={handleClose}>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomModal;
