const API_URL = process.env.REACT_APP_API_URL;


export const sendContact = async (data) => {
    try {
        const response = await fetch(`${API_URL}contato`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};

export const sendColeta = async (data) => {
    try {
        const response = await fetch(`${API_URL}coleta`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};


export const sendCallMe = async (data) => {
    try {
        const response = await fetch(`${API_URL}call-me`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};