import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

import './css/PhoneButtons.css';
const PhoneButtons = () => {
  return (
    <Container fluid className='element-empty'>
      <Container>
        <Row className="justify-content-center text-white box-buttons-summary">
          <Col xl={3} className="d-flex align-items-center p-3" style={{ backgroundColor: 'rgb(55, 60, 89)' }}>
            <FontAwesomeIcon icon={faInstagram} size="lg" className="me-2" />
            <a href='https://www.instagram.com/centraldasbateriasmaceio/' target='_blank' rel="noopener noreferrer" className='text-white'>
              <div>NOSSO INSTAGRAM</div>
              <div>@centraldasbateriasmaceio</div>
            </a>
          </Col>

          <Col xl={3} className="d-flex align-items-center p-3" style={{ backgroundColor: '#468abd' }}>
            <FontAwesomeIcon icon={faPhone} size="lg" className="me-2" />
            <a href='tel:082988490802' target='_blank' rel="noopener noreferrer" className='text-white'>
              <div>LIGUE AGORA MÓVEL</div>
              <div>(82) 98849-0802</div>
            </a>
          </Col>

          <Col xl={3} className="d-flex align-items-center p-3" style={{ backgroundColor: 'rgb(55, 60, 89)' }}>
            <FontAwesomeIcon icon={faPhone} size="lg" className="me-2" />
            <a href='tel:08230343354' target='_blank' rel="noopener noreferrer" className='text-white'>
              <div>LIGUE AGORA FIXO</div>
              <div>(82) 3034-3354</div>
            </a>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default PhoneButtons;
