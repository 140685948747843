import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

import './css/FloatButtons.css';
const FloatButtons = () => {
  return (
    <>
      <a href="https://api.whatsapp.com/send?phone=5582988490802" className="floating-button" title="Fale Conosco pelo WhatsApp" target='_blank' rel="noopener noreferrer">
        <FontAwesomeIcon icon={faWhatsapp} size="lg" />
      </a>
      <a href="tel:+5582988490802" className="floating-button phone" title="Ligue para nós" target='_blank' rel="noopener noreferrer">
        <FontAwesomeIcon icon={faPhone} size="lg" />
      </a>
    </>
  );
};

export default FloatButtons;
