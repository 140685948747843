import React, { useState } from 'react';
import { Container, Row, Col, Button, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './css/Footer.css';
import ModalColeta from './ModalColeta';
import ModalLigue from './ModalLigue';

const Footer = () => {
  const navigate = useNavigate();

  // Estado para controle dos modais
  const [showCallModal, setShowCallModal] = useState(false);
  const [showCollectionModal, setShowCollectionModal] = useState(false);

  const handleScrollToTarget = () => {
    navigate('/');
    setTimeout(() => {
      const element = document.getElementById('quem-somos');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const handleCloseCallModal = () => setShowCallModal(false);
  const handleShowCallModal = () => setShowCallModal(true);

  const handleCloseCollectionModal = () => setShowCollectionModal(false);
  const handleShowCollectionModal = () => setShowCollectionModal(true);

  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer mt-4">
      <Container>
        <Row>
          <Col className="d-flex" md={9}>
            <Nav className="flex-row">
              <Nav.Link onClick={handleScrollToTarget}>Sobre nós</Nav.Link>
              <Nav.Link onClick={handleShowCallModal} activeclassname="active">Quero que me liguem grátis</Nav.Link>
              <Nav.Link onClick={handleShowCollectionModal} activeclassname="active">Solicitação de Coleta</Nav.Link>
            </Nav>
          </Col>
          <Col className="d-flex" md={3}>
            <Button variant="link" href="https://www.facebook.com/centraldasbaterias/?ref=embed_page" target='_blank'><FontAwesomeIcon icon={faFacebook} size="lg" /></Button>
            <Button variant="link" href="https://www.instagram.com/centraldasbateriasmaceio/" target='_blank'><FontAwesomeIcon icon={faInstagram} size="lg" /></Button>
            <Button variant="link" href="https://api.whatsapp.com/send?phone=5582988490802" target='_blank'><FontAwesomeIcon icon={faWhatsapp} size="lg" /></Button>
            <Button variant="link" href="https://www.youtube.com/@centraldasbateriasmaceio1715" target='_blank'><FontAwesomeIcon icon={faYoutube} size="lg" /></Button>
          </Col>
          <Col md={12}>
            <p>&copy; Copyright 2005-{currentYear} | Todos os Direitos Reservados - {process.env.REACT_APP_NAME}.</p>
          </Col>
        </Row>
      </Container>

      {/* Modal para "Quero que me liguem grátis" */}
      <ModalLigue
        showCallModal={showCallModal}
        handleCloseCallModal={handleCloseCallModal}
      />
      {/* Modal para "Solicitação de Coleta" */}
      <ModalColeta
        showCollectionModal={showCollectionModal}
        handleCloseCollectionModal={handleCloseCollectionModal}
      />
    </footer>
  );
};

export default Footer;
