import React, { useState } from 'react';
import { Modal, Form, Spinner, Button } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { sendCallMe } from '../services/apiService';
import CustomModal from './CustomModal';
import './css/Footer.css';

const ModalLigue = (
  {
    showCallModal,
    handleCloseCallModal
  }) => {

  const [phone, setPhone] = useState('');

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // modal de alerta 
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const handleCloseModal = () => setShowModal(false);

  const validateForm = () => {
    const newErrors = {};
    if (!phone) newErrors.phone = ['O telefone é obrigatório.'];
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) return; // não envia se houver erros

    setLoading(true);

    try {
      const response = await sendCallMe({
        phone
      });

      // Verifica se a resposta contém um sucesso
      if (response) {
        handleCloseCallModal();
        setModalTitle(response.title);
        setModalText(response.message);
        setShowModal(true);
        // Limpar campos após o envio
        setPhone('');
        setErrors({});
      }
    } catch (error) {
      let errorResponse = {};
      try {
        errorResponse = JSON.parse(error);
      } catch {
        errorResponse = { error: { message: 'Erro desconhecido' } };
      }

      setErrors(errorResponse.error?.details || { global: 'Ocorreu um erro desconhecido.' });
      if (!errorResponse.error?.details || typeof errorResponse.error?.details === "string") {
        setModalText(typeof errorResponse.error?.details === "string" ? errorResponse.error?.details : "Ocorreu um erro desconhecido");
        setModalTitle("Ocorreu um erro");
        setShowModal(true);
      }

    } finally {
      setLoading(false);
    }
  };



  return (
    <>
      <Modal show={showCallModal} onHide={handleCloseCallModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Quero que me liguem grátis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formPhone" className="mb-3">
              <Form.Label>Telefone</Form.Label>
              <InputMask
                mask="(99) 99999-9999"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  setErrors(prev => ({ ...prev, phone: '' }));
                }}
                isInvalid={!!errors.phone}
              >
                {() => <Form.Control placeholder="Digite seu telefone" isInvalid={!!errors.phone} />}
              </InputMask>
              <Form.Control.Feedback type="invalid">
                {errors.phone ? errors.phone[0] : ''}
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" size="lg" type="submit" className="btn-block mb-2" disabled={loading}>
              {loading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mr-2' />
                  Carregando...
                </>
              ) : 'Enviar'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {/* Modal para mostrar mensagens de sucesso ou erro */}
      <CustomModal
        show={showModal}
        handleClose={handleCloseModal}
        title={modalTitle}
        text={modalText}
      />
    </>
  );
};

export default ModalLigue;
